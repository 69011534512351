<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <b-form-group
            class="mr-1 mb-md-0"
          >
          <!-- <b-button
            v-b-modal.modal-1
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            block
            @click="addData"
          >
            เติมเครดิต
          </b-button> -->
          </b-form-group>
          <!-- <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    block
                    @click="getSMS()"
                  >
                    <feather-icon icon="SearchIcon" />
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div> -->
        </div>
      </b-card-body>
      <b-table
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :current-page="currentPage"
        :items="itemss"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(show_details)="row">

          <b-form-checkbox
            v-model="row.detailsShowing"
            value="true"
            @change="row.toggleDetails"
          >
            รายละเอียดทั้งหมด
          </b-form-checkbox>

          <!-- <button
            class="btn-show"
            :class="row.detailsShowing ? 'active-btn' : ''"
          >
            <i
              :class="row.detailsShowing ? 'fas fa-minus-circle' : 'fas fa-plus-circle'"
              @click="row.toggleDetails"
            />
          </button> -->
        </template>

        <template #row-details="">
          <b-card>
            <table style="width: 100%">
              <thead>
                <tr>
                  <th>จำนวนวันที่ล็อคอิน</th>
                  <th>รางวัล</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ล็อคอินวันที่ 7 ของเดือน</td>
                  <td>{{ prices.day7 }} point</td>
                </tr>

                <tr>
                  <td>ล็อคอินวันที่ 14 ของเดือน</td>
                  <td>{{ prices.day14 }} point</td>
                </tr>

                <tr>
                  <td>ล็อคอินวันที่ 21 ของเดือน</td>
                  <td>{{ prices.day21 }} point</td>
                </tr>

                <tr>
                  <td>ล็อคอินวันที่ 28 ของเดือน</td>
                  <td>{{ prices.day28 }} point</td>
                </tr>

                <tr>
                  <td>ล็อคอินวันอื่นๆ</td>
                  <td>{{ prices.dayother }} point</td>
                </tr>
              </tbody>
            </table>
          </b-card>
        </template>
        <!-- Column: Actions -->
        <template #cell(action)="">
          <button
            class="btn btn-primary btn-sm"
            @click="showModal"
          >
            <i class="fa fa-edit" /> จัดการข้อมูล
          </button>
        </template>
        <template #cell(topup)="data">
          <div class="text-nowrap">
            <feather-icon
              v-if="data.item.status === 'waiting'"
              :id="`invoice-row-${data.item.id}-preview-icon`"
              v-b-modal.modal-1
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="topup(data.item)"
            />
          </div>
        </template>
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
      <b-modal
        ref="my-modal"
        title="แก้ไขข้อมูลรางวัลล็อคอิน"
        size="lg"
        ok-title="บันทึกข้อมูล"
        cancel-title="ยกเลิก"
        @ok="submit"
      >
        <!-- ปิด - เปิด -->
        <div>
          <p class="text-danger">
            ปิด - เปิด ระบบล็อคอิน
          </p>
          <div>
            <b-form-checkbox
              v-model="prices.status"
              class="custom-control-primary"
              name="check-button"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </div><br>
        <b-form-group>
          <label for="dividend">ฝากขั้นต่ำที่สามารถรับรางวัลย้อนหลังได้<span class="text-danger">*</span></label>
          <b-input-group
            prepend="ฝากขั้นต่ำ"
            append="บาท"
          >
            <b-form-input
              v-model="prices.min_dep"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group>
          <label for="dividend">ล็อคอินวันที่ 7 ของเดือน <span class="text-danger">*</span></label>
          <b-input-group
            prepend="ล็อคอินวันที่ 7"
            append="point"
          >
            <b-form-input
              v-model="prices.day7"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ล็อคอินวันที่ 14 ของเดือน <span class="text-danger">*</span></label>
          <b-input-group
            prepend="ล็อคอินวันที่ 14"
            append="point"
          >
            <b-form-input
              v-model="prices.day14"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ล็อคอินวันที่ 21 ของเดือน <span class="text-danger">*</span></label>
          <b-input-group
            prepend="ล็อคอินวันที่ 21"
            append="point"
          >
            <b-form-input
              v-model="prices.day21"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ล็อคอินวันที่ 28 ของเดือน <span class="text-danger">*</span></label>
          <b-input-group
            prepend="ล็อคอินวันที่ 28"
            append="point"
          >
            <b-form-input
              v-model="prices.day28"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group>
          <label for="dividend">ล็อคอินวันอื่นๆ <span class="text-danger">*</span></label>
          <b-input-group
            prepend="ล็อคอินวันอื่นๆ"
            append="point"
          >
            <b-form-input
              v-model="prices.dayother"
              class="text-center"
            />
          </b-input-group>
        </b-form-group>

      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import moment from 'moment-timezone'
import {
  // BRow, BCol,
  BModal, BCard, BTable,
  // BAvatar,
  BFormGroup, BFormSelect, BPagination,
  BInputGroup,
  BFormInput,
  // BButton,
  BCardBody, VBToggle, BOverlay, BIconController,
  // BFormRadioGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    // vSelect,
    // BRow,
    // BCol,
    BModal,
    BCard,
    BTable,
    // BAvatar,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    // BFormRadioGroup,
    BFormCheckbox,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'สถานะรอเติมเงิน' },
        { value: 2, text: 'สถานะเติมเงินสำเร็จ' },
        { value: 3, text: 'ทั้งหมด' },
      ],
      statusOptions: [
        { value: 1, text: 'เปิดใช้งาน' },
        { value: 0, text: 'ปิดใช้งาน' },
      ],
      search_type: null,
      search_val: null,
      show: false,
      depositdata: [],
      member: '',
      amount: '',
      trueData: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'index', label: 'no.', thStyle: { width: '5%' } },
        { key: 'show_details', label: 'แสดงรายละเอียด', thStyle: { width: '80%' } },
        { key: 'action', label: 'Action' },
      ],
      /* eslint-disable global-require */
      itemss: [
        {
          index: 1,
          jackport: '1000',
          percent: '10',
          min_banlance: '1000',
          _showDetails: true,
        },
      ],
      prices: {
        day7: 0,
        day14: 0,
        day21: 0,
        day28: 0,
        dayother: 0,
        min_dep: 0,
        status: false,
      },
      addNewDataSidebar: false,
      datafield: [],
      wheeldata: null,
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.getdata()
  },
  methods: {
    getdata() {
      this.$http
        .get('/dailylogin/index')
        .then(response => {
          if (response.data) {
            this.prices = response.data
          }
        })
        .catch(error => console.log(error))
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    topup(data) {
      this.trueData = data
      this.amount = data.amount
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    async getSMS() {
      this.datafield = []
      this.$http
        .get('/statement/waitinglist')
        .then(response => {
          this.show = false
          console.log(response.data.kbanksms)
          response.data.kbanksms.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'kbank'
          })
          response.data.scbsms.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'scbsms'
          })
          response.data.scbstate.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.resource = 'scbstate'
          })
          // data.push(response.data.scbsms)

          this.datafield.push(...response.data.scbsms)
          this.datafield.push(...response.data.kbanksms)
          this.datafield.push(...response.data.scbstate)
          // eslint-disable-next-line no-undef
          const datasort = this.datafield.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)).reverse()
          this.onFiltered(datasort)
          // console.log(this.datasort)
        })
        .catch(error => console.log(error))
    },
    async submit() {
      if (this.prices.day7 && this.prices.day14 && this.prices.day21 && this.prices.day28 && this.prices.dayother && this.prices.min_dep) {
        this.show = true
        const formData = {
          day7: Number(this.prices.day7),
          day14: Number(this.prices.day14),
          day21: Number(this.prices.day21),
          day28: Number(this.prices.day28),
          dayother: Number(this.prices.dayother),
          min_dep: Number(this.prices.min_dep),
          status: this.prices.status,
        }
        // console.log(formData)
        this.$http
          .post('/dailylogin/setting', formData)
          .then(() => {
            this.show = false
            this.getdata()
            this.Success('บันทึกข้อมูลสำเร็จ')
          })
          .catch(error => {
            this.show = false
            this.SwalError(error.response.data.message)
          })
      } else {
        this.SwalError('กรุณาตั้งค่ารางวัลให้ครบทุกช่อง')
      }
    },
    checkpercent() {
      const x = Number(this.prices.bunus1) + Number(this.prices.bunus2) + Number(this.prices.bunus3) + Number(this.prices.bunus4) + Number(this.prices.bunus5) + Number(this.prices.bunus6) + Number(this.prices.bunus7) + Number(this.prices.bunus8)
      // console.log(x)
      if (x === 100) {
        return true
      }
      return false
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems
      this.totalRows = filteredItems.length
    },
    addData() {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = {}
    },
    editData(data) {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      this.toggleDataSidebar(true)
      this.datasidebar = data
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.btn-show {
    height: 25px;
    width: 25px;
    /* padding: 0; */
    text-align: center;
    border-radius: 5px;
    background-color: #006616;
    color: #fff;
    outline: none;
    border: none;
}
.active-btn {
    background-color: #f44336;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
